import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Head from 'next/head';
import getEntityField from '~/src/lib/getEntityField';
import { withRouter, useRouter } from 'next/router';
import handleAuth from '~/src/lib/handleAuth';
import dynamic from 'next/dynamic';

const Identification = dynamic(
    () => import('~/src/containers/Identification'),
    {
        ssr: false,
    },
);

function App(props) {
    const business = useSelector((state) => state.business);

    const [ready, setReady] = useState(false);
    const lang = useSelector((state) => state.lang);
    const router = useRouter();

    useEffect(() => {
        handleAuth();
        setReady(true);
        window.scrollTo(0, 0);
        document.querySelector('body').setAttribute('id', 'login');
    }, []);

    //console.log(222);
    //console.log(router.pathname);
    //console.log(router);
    //console.log(document.referrer);
    //console.log(document.referrer.indexOf('checkoutsdcd'));

    //https://trello.com/c/RiJOtg1u/2165-revoir-le-process-de-route-quand-on-fait-back-apr%C3%A8s-avoir-pass%C3%A9-une-commande
    if (document.referrer && document.referrer.indexOf('checkout') !== 1) {
        //router.push("/account/orders/takeawayOrderNotDelivered", "/account/orders/takeawayOrderNotDelivered", { locale: lang });
    }

    if (!business) {
        return false;
    }
    if (Object.keys(business).length === 0) {
        return false;
    }

    if (ready === false) {
        return false;
    }

    return (
        <React.Fragment>
            <Head>
                <title>{business.info.name}</title>
                <meta
                    name="description"
                    content={getEntityField(business.info, 'information')}
                />
            </Head>
            <div>
                <Identification />
            </div>
        </React.Fragment>
    );
}
export default App;
